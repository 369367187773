@font-face {
  font-family: 'AcuminProBold';
  src: url('/assets/fonts/AcuminProBold.otf');
  font-weight: bold;
  font-style: normal;
}
@font-face {
  font-family: 'AcuminProBook';
  src: url('/assets/fonts/AcuminProBook.otf');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'AcuminProMedium';
  src: url('/assets/fonts/AcuminProMedium.otf');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'AcuminProSemibold';
  src: url('/assets/fonts/AcuminProSemibold.otf');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'AcuminProLight';
  src: url('/assets/fonts/AcuminProLight.otf');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'AcuminProExtraLight';
  src: url('/assets/fonts/AcuminProExtraLight.otf');
  font-weight: lighter;
  font-style: normal;
}
@font-face {
  font-family: 'AcuminProThin';
  src: url('/assets/fonts/AcuminProThin.otf');
  font-weight: lighter;
  font-style: normal;
}
@font-face {
  font-family: 'AcuminProBookItalic';
  src: url('/assets/fonts/AcuminProBookItalic.otf');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'AcuminProSemiboldItalic';
  src: url('/assets/fonts/AcuminProSemiboldItalic.otf');
  font-weight: normal;
  font-style: normal;
}

h1,
h2,
h3,
h4,
h5,
h6,
.font-h {
  font-family: 'AcuminProBold' !important;
  line-height: normal !important;
}

strong,
.font-semibold {
  font-family: 'AcuminProSemibold' !important;
  line-height: normal !important;
}

.font-h1 {
  font-size: 22px;
}

button {
  font-family: 'AcuminProMedium' !important;
}

.font-book,
a {
  font-family: 'AcuminProBook' !important;
}

input,
.font-regular,
.font-normal,
.fw-normal,
.fw-regular {
  font-family: 'AcuminProMedium' !important;
}

small,
.small,
.font-light,
.fw-light {
  font-family: 'AcuminProExtraLight' !important;
}

.xsmall {
  font-family: 'AcuminProThin' !important;
  font-size: 0.8em !important;
}

.italic-book,
.font-italic {
  font-family: 'AcuminProBookItalic' !important;
}
