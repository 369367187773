.btn,
a {
  line-height: normal !important;
}

.dropdown-toggle::after {
  content: "\f063" !important;
  font-family: 'Font Awesome 6 Pro' !important;
  font-weight: 900 !important;
  border: none !important;
  vertical-align: 0 !important;
}

.btn-outline-dark {
  background-color: #fff !important;
}

.btn-outline-dark:hover,
:not(.btn-check) + .btn-outline-dark:active,
.btn-outline-dark:focus-visible {
  background-color: #e9ecef !important;
  border-color: #000 !important;
  color: #000 !important;
  box-shadow: none;
}

.question-widget .btn {
  border-width: 2px !important;
}

.question-widget .btn:not(.no-3d),
.btn:not(.btn-link):not(.close-sidebar):not(.no-3d):not(:disabled),
.btn-3d:not(.no-3d) {
  border: 1px solid #000;
  box-shadow: 2px 2px 0px 0px #000 !important;
  transition: box-shadow 0.3s ease, background-color 0.3s ease;

  &:hover {
    box-shadow: none !important;
  }
}

.btn-outline-dark.btn-3d {
  background-color: #fff !important;
}

// .button {
//   border: 1px solid #ddd;
//   padding: 10px;
// }

// .primary-button {
//   @extend .button;
//   background-color: $primary-color;
// }
