@import '/src/assets/css/mixins';

.page-container {
  width: 100vw;
  max-width: 100vw;
  min-height: 100%;
  height: 100%;
  padding: 2em 0;

  @include responsive(sm) {
    padding: 1em 0;
  }
}

.login-container {
  position: relative;
  margin: auto;
  max-width: 1000px !important;
  width: 100%;
  padding: 15px;
}

.full-image {
  height: auto;
  width: auto;
  max-width: 100%;
  max-height: 100%;
}

.full-container {
  width: 100%;
  height: 100%;
  min-height: 100%;
  display: flex;
  flex-direction: column;
}

.full-content {
  flex: 1;
}

.modal-body {
  padding: 0 !important;
}

// FORM ICON
.input-icon {
  position: relative;

  input {
    padding-left: 40px !important;
  }

  i {
    position: absolute;
    opacity: 0.8;
    left: 10px;
    top: 50%;

    transform: translateY(-50%);
  }
}
//

// CRUD STYLES
.crud-container {
  display: flex;
  flex-direction: column;
  height: 100%;

  .btn-info,
  .btn-info:hover {
    color: var(--white);
  }

  .table:not(.mat-calendar-table).table > tbody {
    vertical-align: text-top;
  }
}

.col-score {
  min-width: 50px !important;
  max-width: 70px !important;
  font-size: 11px;
}

.table-container:not(.mat-calendar-table) {
  flex: 1;

  th:not(:last-child),
  td:not(:last-child) {
    border-right-width: 1px !important;
  }

  table {
    border-radius: 1em !important;
  }
}

.table:not(.mat-calendar-table) > :not(caption) > * > * {
  border-bottom: none;
}
// END CRUD STYLES

// Bootstrpa settings
.row {
  margin-left: 0 !important;
  margin-right: 0 !important;
  width: 100%;
}

.widget {
  font-size: 1.2em;
}

.clickable {
  cursor: pointer !important;
}

.w-fit {
  width: fit-content;
}

.modal-content {
  border: none !important;
}
// END Bootstrpa settings

.question-widget {
  background-color: inherit;
  border-radius: 6px;
  margin: 15px 0;
  padding: 1em 0;

  @include responsive(lg) {
    margin: 1.5em 0;
  }
}

// DRAG AND DROP STYLES
.cdk-drag-preview {
  box-sizing: border-box !important;
  border-radius: 4px !important;
  box-shadow: 0 5px 5px -3px rgba(0, 0, 0, 0.2),
    0 8px 10px 1px rgba(0, 0, 0, 0.14), 0 3px 14px 2px rgba(0, 0, 0, 0.12) !important;
  z-index: 9999 !important;
}

.cdk-drag-placeholder {
  opacity: 0 !important;
  z-index: 9999 !important;
}

.cdk-drag-animating {
  transition: transform 250ms cubic-bezier(0, 0, 0.2, 1) !important;
  z-index: 9999 !important;
}

.drag-box:last-child {
  border: none !important;
  z-index: 9999 !important;
}

.drag-container.cdk-drop-list-dragging .drag-box:not(.cdk-drag-placeholder) {
  transition: transform 250ms cubic-bezier(0, 0, 0.2, 1) !important;
  z-index: 9999 !important;
}
// DRAG AND DROP STYLES

.cdk-overlay-container {
  z-index: 9999 !important;
}

// EXPANSION PANEL
.mat-expansion-panel-body {
  padding: 0 10px 16px !important;
}
// END EXPANSION PANEL

@media (min-width: 1400px) {
  .container,
  .container-sm,
  .container-md,
  .container-lg,
  .container-xl {
    max-width: 1215px !important;
  }
}
