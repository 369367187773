$sm-breakpoint: 576px;
$md-breakpoint: 768px;
$lg-breakpoint: 992px;
$xl-breakpoint: 1200px;

@mixin responsive($breakpoint) {
  @if $breakpoint == sm {
    @media only screen and (max-width: ($sm-breakpoint - 1)) {
      @content;
    }
  }
  @if $breakpoint == md {
    @media only screen and (max-width: ($md-breakpoint - 1)) {
      @content;
    }
  }
  @if $breakpoint == lg {
    @media only screen and (max-width: ($lg-breakpoint - 1)) {
      @content;
    }
  }
  @if $breakpoint == xl {
    @media only screen and (max-width: ($xl-breakpoint - 1)) {
      @content;
    }
  }
}

@mixin min-responsive($breakpoint) {
  @if $breakpoint == sm {
    @media only screen and (min-width: $sm-breakpoint) {
      @content;
    }
  }
  @if $breakpoint == md {
    @media only screen and (min-width: $md-breakpoint) {
      @content;
    }
  }
  @if $breakpoint == lg {
    @media only screen and (min-width: $lg-breakpoint) {
      @content;
    }
  }
  @if $breakpoint == xl {
    @media only screen and (min-width: $xl-breakpoint) {
      @content;
    }
  }
}

// !USE CASE
// @include responsive(md) {
//   background-color: green;
// }

// En _mixins.scss
@mixin smooth-transition($property, $duration) {
  transition: $property $duration ease-in-out;
}

// !USE CASE
// .element {
//   @include smooth-transition('opacity', 0.3s);
// }
