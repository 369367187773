.show-data {
  animation: fade-out 0.35s linear forwards;
  padding: 0;
}

.hide-data {
  animation: fade-in 0.35s linear forwards;
  padding: 0;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

@keyframes fade-out {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

@keyframes fade-in {
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
  }
}
