/* You can add global styles to this file, and also import other style files */
@import 'assets/css/material-theme';
@import 'assets/css/animation';
@import 'assets/css/colors';
@import 'assets/css/containers';
@import 'assets/css/fonts';
@import 'assets/css/mixins';
@import 'assets/css/variables';

html,
body {
  margin: 0;
  padding: 0;
  font-family: 'AcuminProMedium' !important;
  color: #000;
  line-height: normal !important;
}

body {
  height: 100%;
  width: 100vw;
  min-height: 100vh;
  max-width: 100vw;
  overflow-x: hidden;
  overflow-y: auto;
}
:host {
  min-height: 100%;
  width: 100%;
}
.pac-container {
  z-index: 10000;
}

@import 'assets/css/custom-colors';
@import 'assets/css/custom-buttons';
@import 'bootstrap/scss/bootstrap';
//ng-select styles
@import '~@ng-select/ng-select/themes/default.theme.css';

.cdk-global-overlay-wrapper {
  z-index: 1000 !important;
}

::ng-deep .cdk-overlay-container {
  z-index: 9999 !important;
}
