.bg-yellow {
  background-color: var(--yellow);
}
.bg-green {
  background-color: var(--green);
}
.bg-green2 {
  background-color: var(--green2);
}

.questionnaire-list .widget:nth-child(even) {
  background-color: var(
    --gray
  ) !important; /* Color de fondo para las tarjetas pares */
}

.questionnaire-list .widget:nth-child(odd) {
  background-color: var(
    --white
  ) !important; /* Color de fondo para las tarjetas impares */
}

::ng-deep .mat-drawer-container {
  background-color: var(--white) !important;
}

.table-container .table-responsive {
  border-radius: 8px;
}

.table-container table {
  background-color: var(--white) !important;
  border-radius: 10px !important;

  thead {
    color: var(--black) !important;

    button {
      color: var(--black) !important;
    }
  }
}
// table .border-warning,
table:not(.mat-calendar-table) thead.border-warning,
table:not(.mat-calendar-table) tbody.border-warning,
// .table-responsive .border-warning,
.table-container td,
// .border-warning tr,
// .border-warning tbody,
.table-container th {
  // border-color: var(--orange2) !important;
  --bs-border-opacity: 0.4 !important;
}
table:not(.mat-calendar-table) thead tr {
  background-color: var(--orange2) !important;
}
.table-striped > tbody > tr:nth-of-type(odd) > * {
  --bs-table-color-type: var(--black) !important;
  --bs-table-bg-type: var(--orange4) !important;
}
